export const ROADNET_STATES = [
  { code: 'AS', state: 'Aguascalientes' },
  { code: 'BC', state: 'Baja California' },
  { code: 'BS', state: 'Baja California Sur' },
  { code: 'CC', state: 'Campeche' },
  { code: 'CS', state: 'Chiapas' },
  { code: 'CH', state: 'Chihuahua' },
  { code: 'CL', state: 'Coahuila de Zaragoza' },
  { code: 'CM', state: 'Colima' },
  { code: 'DF', state: 'Ciudad de México' },
  { code: 'DG', state: 'Durango' },
  { code: 'GT', state: 'Guanajuato' },
  { code: 'GR', state: 'Guerrero' },
  { code: 'HG', state: 'Hidalgo' },
  { code: 'JC', state: 'Jalisco' },
  { code: 'MC', state: 'México' },
  { code: 'MN', state: 'Michoacán de Ocampo' },
  { code: 'MS', state: 'Morelos' },
  { code: 'NT', state: 'Nayarit' },
  { code: 'NL', state: 'Nuevo León' },
  { code: 'OC', state: 'Oaxaca' },
  { code: 'PL', state: 'Puebla' },
  { code: 'QT', state: 'Querétaro' },
  { code: 'QR', state: 'Quintana Roo' },
  { code: 'SP', state: 'San Luis Potosí' },
  { code: 'SL', state: 'Sinaloa' },
  { code: 'SR', state: 'Sonora' },
  { code: 'TC', state: 'Tabasco' },
  { code: 'TS', state: 'Tamaulipas' },
  { code: 'TL', state: 'Tlaxcala' },
  { code: 'VZ', state: 'Veracruz de Ignacio de la Llave' },
  { code: 'YN', state: 'Yucatán' },
  { code: 'ZS', state: 'Zacatecas' }
];
