import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { ProductSku, ProductSkuResponse } from '../../interfaces/product-sku';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ProductSkuProvider {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Gets all related shipper accounts by given shipperId
   * @param {string} shipperId - Optional Param to get related accounts
   * @returns {Promise<ProductSkuResponse>} Shipper found accounts
   */
  public async getProducts(shipperId?: string): Promise<ProductSkuResponse> {
    const shipperOid = shipperId ?? this._appService.getShipperOid();
    return await this.http.get<ProductSkuResponse>(`${apiUrl}/products?shipperId=${shipperOid}`).toPromise();
  }

  /**
   * @description Gets products by product identifiers list and order account names list
   * @param {string} shipperId The current shipper object id
   * @param {Array<string>} productCodeList Array with all product identifiers given through excel template
   * @returns {Promise<ProductSkuResponse>} Response from core api with products or error message
   */
  public async getProductsByCodeList(shipperId: string, productCodeList: Array<string>): Promise<ProductSkuResponse> {
    return await this.http.post<ProductSkuResponse>(`${apiUrl}/products/shipper/${shipperId}/byCodeAndAccount`,
      {productCodeList}).toPromise();
  }

  /**
   * @description Generate request for create a new product
   * @param {ProductSku} body to send in the request
   * @returns {Promise<ProductSkuResponse>} response of shipper
   */
  public async createProduct(body: ProductSku): Promise<ProductSkuResponse> {
    return await this.http.post<ProductSkuResponse>(`${apiUrl}/products/`, body).toPromise();
  }

  /**
   * @description Gets an sku product by provided shipperId and product code
   * @param {string} productCode - Sku's specific product code
   * @param {string} shipperId - Optional Param to get related accounts
   * @returns {Promise<ProductSkuResponse>} Products found by shipper
   */
  public async getProductByCode(shipperId?: string, productCode?: string): Promise<ProductSkuResponse> {
    const shipperOid = shipperId ?? this._appService.getShipperOid();
    return await this.http.get<ProductSkuResponse>(`${apiUrl}/products?shipperId=${shipperOid}&productCode=${productCode}`).toPromise();
  }

  /**
   * @description Generate request for edit the product
   * @param {ProductSku} body to send in the request
   * @returns {Promise<ProductSkuResponse>} response of shipper
   */
  public async updateProduct(body: ProductSku): Promise<ProductSkuResponse> {
    return await this.http.put<ProductSkuResponse>(`${apiUrl}/products/${body._id}`, body).toPromise();
  }
}
