import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AccountByShipper, Carrier, Driver, Zone, Shipper } from '../../interfaces';
import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { Tariff, TariffResponse } from '../../interfaces/tariff';

const apiUrl = environment.shipmentRequestApiUrl;
const secondaryBlobStorage = environment.secondaryBlobStorage;
const VALUE_KEY = 'mexico.json';

@Injectable({
  providedIn: 'root'
})
export class TariffService {

  constructor(
    private _appService: AppService,
    private http: HttpClient,
  ) { }

  /**
   * @description This function returns a array of all the states from mexico
   * @return {Array<Object>} Response of endpoint
   */
  public async getStates(): Promise<Array<Object>> {
    return await this.http.get<Array<Object>>(secondaryBlobStorage + 'statics/' + VALUE_KEY).toPromise();
  }

  /**
   * @description Create the tariff object
   * @param {Tariff} tariff object to create
   * @return {Promise<TariffResponse>} Response of endpoint
   */
  public async createTariff(tariff: Tariff): Promise<TariffResponse> {
    return await this.http.post<TariffResponse>(apiUrl + '/tariffs/', tariff).toPromise();
  }

  /**
   * @description Update the tariff object
   * @param {Tariff} tariff object to update
   * @return {Promise<TariffResponse>} Response of endpoint
   */
  public async updateTariff(tariff: Tariff): Promise<TariffResponse> {
    return await this.http.put<TariffResponse>(apiUrl + '/tariffs/' + tariff.id, tariff).toPromise();
  }

  /**
   * @description Get all tariffs
   * @param {string} shipperId querystring for filter of tariff
   * @param {string} vehicleTypeName the typevehicle for filter of tariff optional
   * @param {string} originState the origin state of tariff optional
   * @param {string} destinationState the destination state of tariff optional
   * @param {string} originCity the origin city of tariff optional
   * @param {string} destinationCity the destination city of tariff optional
   * @return {Promise<TariffResponse>} Response of endpoint
   */
  public async getTariffs(shipperId?: string,
    vehicleTypeName?: string,
    originState?: string,
    destinationState?: string,
    originCity?: string,
    destinationCity?: string): Promise<TariffResponse> {
    const shipperOId = '?shipperId=' + ( shipperId ? shipperId : this._appService.getShipperOid() );
    let requestQuery = '';
    if (originState && originState !== '') {
      requestQuery += originState ? '&originState=' + originState : null;
    }
    if (originCity && originCity !== '') {
      requestQuery += originCity ? '&originCity=' + originCity : null;
    }
    if (destinationState && destinationState !== '') {
      requestQuery += destinationState ? '&destinationState=' + destinationState : null;
    }
    if (destinationCity && destinationCity !== '') {
      requestQuery += destinationCity ? '&destinationCity=' + destinationCity : null;
    }
    if (vehicleTypeName && vehicleTypeName !== '') {
      requestQuery += vehicleTypeName ? '&vehicleTypeName=' + vehicleTypeName : null;
    }
    return await this.http.get<TariffResponse>(`${apiUrl}/tariffs/${shipperOId}${requestQuery}`).toPromise();
  }

  /**
   * @description Delete an tariff
   * @param {string} tariffId id to delete
   * @param {string} userId id from user log in
   * @returns {Promise<TariffResponse>} Updated tariff
   */
  public async deleteTariff(tariffId: string, userId: String): Promise<TariffResponse> {
    return await this.http.delete<TariffResponse>(apiUrl + '/tariffs/' + tariffId + '/' + userId).toPromise();
  }
}
