import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from '../../app.service';
import { environment } from '../../../environments/environment';
import { LocationsByPostalCodes, MexLocationCodePostalResponse, MexLocationsStateResponse } from '../../interfaces/mex-locations-catalogs';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class MexLocationsCatalogService {

  constructor(private http: HttpClient, private _appService: AppService) { }

  /**
   * @description Generate request and get all locations by state as result
   * @returns {Promise<MexLocationsStateResponse>} response with data
   */
  public async getStates(): Promise<MexLocationsStateResponse> {
    return await this.http.get<MexLocationsStateResponse>(`${apiUrl}/mexLocationsCatalogs/states`).toPromise();
  }

  /**
   * @description Get all locations by state and municipalities as result
   * @returns {Promise<MexLocationsStateResponse>} response with data
   */
  public async getStatesAndMunicipalities(): Promise<MexLocationsStateResponse> {
    return await this.http.get<MexLocationsStateResponse>(`${apiUrl}/mexLocationsCatalogs/statesAndMunicipalities`).toPromise();
  }

  /**
   * @description Get all locations by codePostal list
   * @returns {Promise<MexLocationsStateResponse>} response with data
   */
  public async getLocationsByPostalCodes(codePostalList: LocationsByPostalCodes): Promise<MexLocationCodePostalResponse> {
    return await this.http.post<MexLocationCodePostalResponse>(
      `${apiUrl}/mexLocationsCatalogs/locationsOriginAndDestinationPostalCodes`, codePostalList
      ).toPromise();
  }
}
