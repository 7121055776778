import { ChargeType } from '../../interfaces/marketplace';

export const ChargeTypesArray: Array<ChargeType> = [
  {
    id: '6021ec8fa7ec5eb833605787',
    name: 'Emplayado'
  },
  {
    id: '60231f490a5a2c33c5edfa1d',
    name: 'Entarimado',
  },
  {
    id: '5f1989d226f713b4bcf620e4',
    name: 'Granel'
  }
];
