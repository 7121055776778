export const MEDISTIK_SERVICE_TYPES = [
  { symbol: 'A', description: 'Mensajería Express' },
  { symbol: 'B', description: 'Mensajería Terrestre' },
  { symbol: 'C', description: 'Distribuidor Foráneo' },
  { symbol: 'D', description: 'Distribuidor Local' },
  { symbol: 'E', description: 'Distribuidor con Cita Foráneo' },
  { symbol: 'F', description: 'Personalizado Seco' },
  { symbol: 'G', description: 'Personalizado Refrigerado' },
  { symbol: 'H', description: 'Ruta Local Seca' },
  { symbol: 'I', description: 'Ruta Local Refrigerada' },
  { symbol: 'J', description: 'Ruta Local Seca antes de 13:00' },
  { symbol: 'K', description: 'Ruta Local Refrigerada antes de 13:00' },
  { symbol: 'L', description: 'Ruta definida (Consolidado por calendario)' },
  { symbol: 'M', description: 'Servicio Dedicado' },
  { symbol: 'N', description: 'Cliente Recoge' },
  { symbol: 'O', description: 'Consolidado Botox' },
  { symbol: 'P', description: 'Promocionales' },
  { symbol: 'Q', description: 'Servicio Interalmacenes (Clientes)' },
  { symbol: 'R', description: 'Personalizado con Custodia' },
  { symbol: 'T', description: 'Personalizado Sánchez-Allergan' },
  { symbol: 'U', description: 'Material Peligroso' },
  { symbol: 'V', description: 'Novo Ruta Local Refrigerada' },
  { symbol: 'W', description: 'Muestras Médicas' },
  { symbol: '0', description: 'Terrestre Refrigerado' },
  { symbol: '1', description: 'Terrestre Consolidado' },
  { symbol: '2', description: 'Mensajería + Porteo' },
  { symbol: '3', description: 'Mensajería' },
  { symbol: '4', description: 'Porteo Local' }
];
