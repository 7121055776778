import { TransportistData } from '../../interfaces/index';

export const TransportistsDefault: Array<TransportistData> = [
  {
    id: 1,
    name: 'Todos los transportistas',
  },
  {
    id: 2,
    name: 'Transportista privado',
  },
  {
    id: 3,
    name: 'Grupo de transportistas',
  }
];
